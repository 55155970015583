import React, { useState, useEffect } from "react";
import "./App.scss";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./libs/errorLib";
import axios from "axios";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  function onSubmit(event) {
    event.preventDefault();
    setFormEnabled(false);
    axios
      .post(
        `https://l20fkdyo5h.execute-api.us-east-1.amazonaws.com/prod/contact-us`,
        { email }
      )
      .then((res) => {
        alert("Kul! Vi håller dig uppdaterad!");
        setFormEnabled(true);
      })
      .catch((error) => {
        alert("Något gick fel. Kom tillbaka en annan dag och testa igen.");
      });
  }

  function updateEmail(event) {
    setEmail(event.target.value);
  }

  return (
    !isAuthenticating && (
      <div className="App container">
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <header id="header">
            <img
              alt="Bondesemester logga"
              src="assets/svg/logo-white.svg"
              style={{ maxWidth: 400 }}
            />
            <p>
              Upplev Sveriges lantgårdar
              <br />
            </p>
          </header>
          <form id="signup-form" onSubmit={onSubmit}>
            <input
              type="email"
              name="email"
              autoFocus
              defaultValue={email}
              onChange={updateEmail}
              id="email"
              placeholder="E-postadress"
            />
            <input
              type="submit"
              disabled={!formEnabled}
              value="Håll mig uppdaterad"
            />
          </form>
        </AppContext.Provider>
      </div>
    )
  );
}

export default App;
