const dev = {
  STRIPE_KEY: "pk_test_51IC9IoITwcxKX7E0spCewpVEPcApOP9Dthbqya8BnzL7sHIHr4pyy5iZsOH4TS6PRg1EyOah51tnZS2Pmqt4Aj1100kgz1vjIr",
  s3: {
    REGION: "us-east-1",
    BUCKET: "dev-bondesemester-infra-s3-uploads4f6eb0fd-11acyx0i2o3qi"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dev.infra.bondesemester.se"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_URIZDC1KF",
    APP_CLIENT_ID: "4vk6e66sacm81evhfv3bobebhk",
    IDENTITY_POOL_ID: "us-east-1:419503d6-ba02-4242-99b6-24204ef40886"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_51IC9IoITwcxKX7E0spCewpVEPcApOP9Dthbqya8BnzL7sHIHr4pyy5iZsOH4TS6PRg1EyOah51tnZS2Pmqt4Aj1100kgz1vjIr",
  s3: {
    REGION: "us-east-1",
    BUCKET: "prod-bondesemester-infra-s3-uploads4f6eb0fd-163alg0mnns2s"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://infra.bondesemester.se"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_1evmUHb3a",
    APP_CLIENT_ID: "5293d4pbqkafj3o2i8no2712iu",
    IDENTITY_POOL_ID: "us-east-1:a8f4cbd9-035f-451a-9b0f-84cabc8bb3cb"
  }
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;